.container{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    width: 100vw;
    background: url(../../assets/images/404.jpg) center no-repeat fixed;
    background-size: cover;
}

.message{
    color: white;
    font-size: larger;
    font-weight: bolder;
}