$base-color: #053C5E;
$accent-color: #5E4B56;
// $accent-color: #78a67c;
$secondary-color: #5DB139;
$tertiary-color: #4b91db;
$nice-color: #eeeada;
$gray: #35302F;
$gray-light: #969892;

// $secondary-color: #50594f;
// $tertiary-color: #9cbea0;
// $nice-color: #eeeada;


$font-color-dark: #121212;
$font-color-white: #fff;
$max-width: 1560px;
$gutter:20px;
$finger-size:40px;