.button{
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 0 20px;
    width: fit-content;
    height: 60px;
    border-radius: 20px;
    background-color: transparent;
    box-shadow: 0 0 0 1px #e6e6e6;
    transition: box-shadow 0.2s ease-in-out;

    text-transform: uppercase;
    text-decoration: none;
    color: black;
    font-size: larger;

    cursor: pointer;
    

    &:hover{
        box-shadow: 0 0 0 2px #000;

    }

}

