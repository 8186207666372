.project{
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    // background-color: aquamarine;
    background-color: rgba($color: #F9F6EE, $alpha: 0.95);
    width: 250px;
    height: 300px;
    padding: 25px 20px;
    margin: 20px;
    border-radius: 20px 35px;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    &_bottom{
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-end;
        align-self: center;
        background-color: transparent;
    }
    &_type{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-self: flex-end;
        text-transform: capitalize;
        font-weight: 500;
        font-style: italic;
        background: linear-gradient(to right, #365dac 0%, #1d0885 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        transform:  translateX(20px) rotate(45deg);
    }

    &_name{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        // background-color: transparent;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-weight: bolder;
        font-size: 1.35rem;
        background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &_links{
        background-color: transparent;
        color: #000;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-self: center;
        text-align: center;
        width: 100%;
        height: 100%;
        gap: 5px;
        padding: 10px 0;
        
        &_link{
            width: 70px;
            height: 30px;
            padding: 5px 10px;
            cursor: pointer;

            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
            border-radius: 5px;
            transition: all 0.2s ease-in-out;
            &:hover{
                box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
                transform: rotate3d(1,2,3,5deg);
            }

        }
    }

    &_tags{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-self: center;
        background-color: transparent;
        width: 100%;

        & p{
            background-color: transparent;
            margin: 0;
        }
        &_tag{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-self: center;
            margin: 5px;
            padding: 5px;
            border-width: 1px;
            border-radius: 10px;
            box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
            text-transform: uppercase;
            font-size: small;
            background-color: transparent;
            color: #000;

        }
       
    }

    &_technologies{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-self: center;
        background-color: transparent;
        & p{
            background-color: transparent;
            margin: 0;
        }
        &_technology{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-self: center;
            margin: 5px;
            padding: 5px;
            border-width: 1px;
            border-radius: 10px;
            box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
            text-transform: uppercase;
            font-size: small;
            background-color: transparent;
            color: #000;

        }
       
    }

    &_made{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-self: center;
        height: 30px;
        color: red;
        text-decoration: dashed;
    }

}