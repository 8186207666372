.articles{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 100vh;
    gap: 5px;
}

@media screen and (max-width:780px) {
    .articles{
        justify-content: flex-start;
    }    
}