.page{
    height: 100vh;
    width: 100vw;
    display: flex;
    // flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    flex: 1;
    
    &_top{
        flex: 1;
        width: 100%;
        min-height: 150px;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        // background-color: aqua;
    }
    
    &_bottom{
        width: 100%;
        flex: 2;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        gap: 10px;
        padding-bottom: 200px;
        // background-color: salmon;
    }
    &_row{
        flex-direction: row;
    }
    &_column{
        flex-direction: column;
    }
}

@media screen and (min-width: 768px){
    .page{
        width: 75%;
    }

}