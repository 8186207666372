.projects{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100vh;

    &_header{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        font-weight: bolder;
    }

    &_body{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-wrap: wrap;
    }
    // background: url(../../assets/images/projects.jpg) center no-repeat fixed;
    // background-size: cover;
    
}
