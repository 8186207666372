.article{
    width: 100%;
    // max-width: 1000px;
    min-height: fit-content;

    cursor: pointer;

    // background: #2980b9;  /* fallback for old browsers */
    // background: -webkit-linear-gradient(to right, #2c3e50, #2980b9);  /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(to right, #2c3e50, #2980b9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    // border-radius: 25px;
    padding: 10px;

    border-bottom: 1px solid rgba(35, 33, 33, 0.2);
    &:last-child{
        border-bottom: none;
    }

    &_top{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &_date{
            display: flex;
            justify-content: flex-end;
            padding-right: 10px;
            font-style: italic;
            font-weight: 300;
        }

        &_title{
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bolder;
            font-size: larger;
            & p{
                // padding-left: 5px;
            }
        }
    }
    &_bottom{
        
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
        &_tags{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-self: center;
            & p{
                margin: 0;
            }
            &_tag{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-self: center;
                padding: 0 10px 0 0;
                text-transform: uppercase;
                font-size: small;

                border-width: 1px;
                border-radius: 10px;
                box-shadow: rgba(47, 46, 46, 0.2) 0px 10px 10px -10px;
            }
        
        }
    }

}


@media screen and (max-width:780px) {
    .article{
        width: 90%;
        &_top{
            flex-direction: column-reverse;
            &_date{
                padding: 0;
            }
            &_title{
                padding: 0;
            }
        }
        &_bottom{
            flex-direction: column;
            &_tags{
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-self: center;
                &_tag{
                    padding: 0 10px 0 0;
                }
            }
        }        
    }    
}