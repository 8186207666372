@import "../../assets/styles/variables";
@mixin page_button(){
    cursor: pointer;
    font-size: 2em;
    position: fixed;
    top: 50%;
    transform: translateY(-50%) ;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    writing-mode: vertical-rl;
    text-orientation: upright;
    user-select: none;
    opacity: 0.7;

    transition: all 0.6s ease-in-out;

    &::before{
        content: "|";
    }
    &::after{
        content: "|";
    }

    &:hover{
        opacity: 1;
        transform: translateY(-50%) scale(1.2);
    }
}

.carousel{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    &_button{

        &_left{
            @include page_button();
            left: 20px;
            
        }
        
        &_right{
            @include page_button();
            right: 20px;
        }

        &_bottom{
            right: 40px;
            bottom: 20px;
            font-size: 1.2em;
            position: fixed;
            writing-mode: unset;
            text-orientation: unset;
            user-select: none;
            opacity: 0.7;
            display: flex;
            justify-self: center;
            align-self: center;
            align-items: center;
            gap: 5px;

            &_filler{

                &_empty{            
                    position: sticky;            
                    width: 100px;
                    height: 7px;
                    border-radius: 5px;
                    border: 1px solid black;
                    transition: all 0.6s ease-in-out;
                }

                &_filled{
                    position: fixed;
                    width: 0;
                    height: 7px;
                    border-radius: 5px;
                    background-color: black;
                    transition: all 0.6s ease-in-out;
                    transform: translate(1px, -8px);
                }
            }
        }

        &_disabled{
            opacity: 0.2;
            cursor: not-allowed;

            &:hover{
            transform: translateY(-50%) scale(1);
            opacity: 0.2;
            color: unset;
            }
        }
    }

    &_content{
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;


        &_name{
            position: absolute;
            top: 10px;
            font-size: 2.5em;
            font-weight: bolder;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 10px;
            transition: all 0.6s ease-in-out;
            color: $tertiary-color;
        }

                  
        // &_type{
        //     display: flex;
        //     flex-direction: column-reverse;
        //     justify-content: center;
        //     align-self: flex-end;
        //     text-transform: capitalize;
        //     font-weight: 500;
        //     font-style: italic;
        //     background: linear-gradient(to right, #365dac 0%, #1d0885 100%);
        //     -webkit-background-clip: text;
        //     -webkit-text-fill-color: transparent;
    
        //     transform:  translateX(20px) rotate(45deg);
        // }
    
        &_links{
            background-color: transparent;
            color: #000;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            align-self: center;
            text-align: center;
            gap: 5px;
            padding: 10px 0;
            
            &_link{
                width: 70px;
                height: 30px;
                padding: 5px 10px;
                cursor: pointer;
    
                box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
                border-radius: 5px;
                transition: all 0.2s ease-in-out;
                &:hover{
                    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
                    transform: rotate3d(1,2,3,5deg);
                }
    
            }
        }
    
        &_tags{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-self: center;
            background-color: transparent;
            width: 100%;
    
            & p{
                background-color: transparent;
                margin: 0;
            }
            &_tag{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-self: center;
                margin: 5px;
                padding: 5px;
                border-width: 1px;
                border-radius: 10px;
                box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
                text-transform: uppercase;
                font-size: small;
                background-color: transparent;
                color: #000;
    
            }
           
        }
    
        &_technologies{
            background-color: aqua;
            background-color: transparent;
            width: 100%;
            height: 100px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-self: center;


            flex-shrink: 0;
            min-width: 100%;
            gap: var(--gap);
            animation: scroll var(--duration) linear infinite;

            &_wrapper{
                --size: clamp(10rem, 1rem + 10vmin, 30rem);
                --gap: calc(var(--size) / 14);
                --duration: 30s;
                --scroll-start: calc(100% + var(--gap));
                --scroll-end: calc(-100% - var(--gap));
                display: flex;
                overflow: hidden;
                user-select: none;
                gap: var(--gap);
                width: 100%;


                mask-image: linear-gradient(
                    var(--mask-direction, to right),
                    hsl(0 0% 0% / 0),
                    hsl(0 0% 0% / 1) 20%,
                    hsl(0 0% 0% / 1) 80%,
                    hsl(0 0% 0% / 0)
                );
            }

            &_technology{
                text-transform: uppercase;
                -webkit-text-stroke: 1px white;
                -webkit-text-stroke-color: #000 ;
                font-size: 3rem;
                font-weight: 800;
                background-color: transparent;
                color: transparent;
            }
           
        }
    }
}

@keyframes scroll {
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}

@media screen and (max-width:780px) {
    .carousel{

        &_button{

            &_left{
                left: 0;
                
            }
            
            &_right{
                right: 0;
            }
        }
            
    }    
}