.home{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 100vh;
    gap: 20px;

    &_profile{

        img{
            height: 300px;
            object-fit: cover;
            border: 20px solid #fff;
            transform: rotate(-5deg);
            border-radius: 10px;
        }
    }

    &_content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        &_social{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center;
            gap: 10px;
            padding-top: 15px;
        }
    }

    &_top_container{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        width: 100%;

    }

    &_social_container{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
        gap: 10px;
    }
        
    &_title{
        background-color: transparent;
        font-family: 'Pacifico', cursive;
        font-weight: bold;
        font-size: 5vh;
        &_sub{
            background-color: transparent;
            font-family: 'Pacifico', cursive;
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width:780px) {
    .home{
        flex-direction: column;
        width: 100%;
        padding-bottom: 100px;

        &_profile{

            img{
            }
        }
    }    
}