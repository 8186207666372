*{
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; 
    scrollbar-width: none;

    margin: 0;
    padding: 0;

    body{
        color: #121212;
        background-color: #F9F6EE;

    }
    
    h1{
        font-family: 'Pacifico', cursive;
        font-weight: bold;
        font-size: 5vh;
        margin: 0;
    }
}

@media screen and (max-width: 768px) {
    // html{
    //     margin-bottom: 150px;
    // }
    
}

@import "./global"
, "./index"
, "./mixins"
, "./variables"
, "./animations"